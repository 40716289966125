const CONFIG = {
  // 开发环境配置
  development: {
    assetsPath: "/static", // 静态资源路径
    // http://192.168.0.107:8090
    baseURL: "http://localhost:8080/garbage/",   // 本地// 
    // baseURL: "http://192.168.0.117:8090/garbage/",   // 本地
    // baseURL: "http://192.168.0.119:8090/garbage/",   // 测试服///
    // baseURL: "http://123.60.73.210:8090/garbage/", //  线上
    // baseURL: "https://web.huajiuddd.com/garbage/", //  线上
    // https://www.huajiuddd.com/#/login
    // baseURL: "http://101.132.226.168:8090/garbage/", //测试服
    // action="http://192.168.0.103:8090/garbage/oss/file/upload"
    // baseUrl: 'http://192.168.1.5:9002/api', // 后台接口请求地址线下本地

  },

  // 生产环境配置
  production: {
    assetsPath: "/static", // 静态资源路径
    // baseURL: "http://192.168.0.106:8090/garbage/", // 后台接口	请求/地址
    // baseURL: "http://192.168.0.117:8090/garbage/",   // 本地
    // baseURL: "http://192.168.0.119:8090/garbage/",   // 测试服/
    // baseURL: "http://123.60.73.210:8090/garbage/", //  线上
    baseURL: "https://web.huajiuddd.com/garbage/", //  线上/

  },
};
export default CONFIG[process.env.NODE_ENV];
